import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Logo from 'src/components/Logo';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import LoadingButton from '@material-ui/lab/LoadingButton'
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'src/redux/actions'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  },
  header: {
    top: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5)
    }
  }
}));

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children }) {
  const classes = useStyles()
  const { isLoading, isAuthenticated } = useAuth();
  const { email, emailVerified, authUpdateLoading, accessToken } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_PAGE.auth.login} />;
  }

  if (!emailVerified) {
    return (
      <Page title="Confirm Email | Brevi Assistant" className={classes.root}>
        <header className={classes.header}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </header>

        <Container>
          <Box sx={{ maxWidth: 520, mx: 'auto' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                alt="sent email"
                src="/static/icons/ic_email_sent.svg"
                sx={{ mb: 5, mx: 'auto' }}
              />
              <Typography variant="h3" gutterBottom>
                Please verify your email.
              </Typography>
              <Typography>
                To use our services and dashboard, please verify your email first.
                <br />
                A confirmation email was previously sent to &nbsp;<strong>{email}</strong>
                <br />
                Please check your email.
              </Typography>

              <LoadingButton
                size="large"
                variant="contained"
                sx={{ mt: 5 }}
                onClick={() => dispatch(updateUser(accessToken))}
                disabled={authUpdateLoading}
                pending={authUpdateLoading}
              >
                I confirmed my email
              </LoadingButton>
            </Box>
          </Box>
        </Container>
      </Page>
    )
  }

  return <>{children}</>;
}

export default AuthProtect;
