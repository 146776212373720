import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_APP, PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  // dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  usersList: <MIcon src={path('users-list')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
  library: <MIcon src={path('library')} />,
  dashboard: <MIcon src={path('dashboard')} />,
  newSummary: <MIcon src={path('new-summary')} />,
  editSummary: <MIcon src={path('edit-summary')} />,
  billing: <MIcon src={path('billing')} />,
  integrations: <MIcon src={path('integrations')} />,
  researchPapers: <MIcon src={path('research-papers')} />,
  exploreScience: <MIcon src={path('explore-science')} />,
  searchHealthcare: <MIcon src={path('search-healthcare')} />,
  newSingleSummary: <MIcon src={path('new-single-summary')} />,
  editSingleSummary: <MIcon src={path('edit-single-summary')} />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'multi document summaries',
    items: [
      {
        title: 'all summaries',
        href: PATH_APP.general.summarize.root,
        icon: ICONS.dashboard
      },
      {
        title: 'new summary',
        href: PATH_APP.general.summarize.new,
        icon: ICONS.newSummary
      },
      {
        title: 'edit summary',
        href: PATH_APP.general.summarize.edit,
        icon: ICONS.editSummary
      }
    ]
  },
  // SINGLE SUMMARIES
  // ----------------------------------------------------------------------
  {
    subheader: 'single abstractive summaries',
    items: [
      {
        title: 'all single summaries',
        href: PATH_APP.general.singleSummarize.root,
        icon: ICONS.dashboard
      },
      {
        title: 'new single summary',
        href: PATH_APP.general.singleSummarize.new,
        icon: ICONS.newSingleSummary
      },
      {
        title: 'edit single summary',
        href: PATH_APP.general.singleSummarize.edit,
        icon: ICONS.editSingleSummary
      }
    ]
  },
  // LIBRARY
  // ----------------------------------------------------------------------
  {
    subheader: 'library',
    items: [
      {
        title: 'library',
        href: PATH_APP.library.library,
        icon: ICONS.library
      },
      {
        title: 'upload',
        href: PATH_APP.library.upload,
        icon: ICONS.upload
      },
      {
        title: 'research papers',
        href: PATH_APP.library.researchPapers.root,
        icon: ICONS.researchPapers,
        items: [
          {
            title: 'Find Research Papers',
            href: PATH_APP.library.researchPapers.root
          },
          {
            title: 'Automation List',
            href: PATH_APP.library.researchPapers.list
          },
          {
            title: 'create automation task',
            href: PATH_APP.library.researchPapers.create
          },
        ]
      },
      {
        title: 'Science/Education',
        href: PATH_APP.library.exploreScience.root,
        icon: ICONS.exploreScience,
        items: [
          {
            title: 'Explore',
            href: PATH_APP.library.exploreScience.root
          },
          {
            title: 'Automation List',
            href: PATH_APP.library.exploreScience.list
          },
          {
            title: 'create automation task',
            href: PATH_APP.library.exploreScience.create
          },
        ]
      },
      {
        title: 'Healthcare Information',
        href: PATH_APP.library.searchHealthcare.root,
        icon: ICONS.searchHealthcare,
        items: [
          {
            title: 'Search Healthcare',
            href: PATH_APP.library.searchHealthcare.root
          },
          {
            title: 'Automation List',
            href: PATH_APP.library.searchHealthcare.list
          },
          {
            title: 'create automation task',
            href: PATH_APP.library.searchHealthcare.create
          },
        ]
      },
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // {
      //   title: 'user',
      //   icon: ICONS.user,
      //   href: PATH_APP.management.user.root,
      //   items: [
      //     {
      //       title: 'profile',
      //       href: PATH_APP.management.user.profile
      //     },
      //     {
      //       title: 'cards',
      //       href: PATH_APP.management.user.cards
      //     },
      //     {
      //       title: 'list',
      //       href: PATH_APP.management.user.list
      //     },
      //     {
      //       title: 'account',
      //       href: PATH_APP.management.user.account
      //     }
      //   ]
      // },
      {
        title: 'account',
        href: PATH_APP.management.user.account,
        icon: ICONS.user
      },
      {
        title: 'users list',
        href: PATH_APP.management.user.list,
        icon: ICONS.usersList,
      },
      {
        title: 'billing',
        href: PATH_APP.management.billing,
        icon: ICONS.billing,
      },
      {
        title: 'integrations',
        href: PATH_APP.management.integrations,
        icon: ICONS.integrations,
      },
    ]
  },
];

export default navConfig;
