import Config from 'src/common/Config'
import axios from 'src/utils/axios';

import {
  GET_AUTOMATIONS_PENDING,
  GET_AUTOMATIONS_SUCCESS
} from '../types'

export const getAutomations = (accessToken) => {
  return (dispatch) => {
    dispatch({ type: GET_AUTOMATIONS_PENDING })

    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/automations`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      dispatch({ type: GET_AUTOMATIONS_SUCCESS, automations: res.data.automations })
    }).catch(err => 'fail')
  }
}