import React, { useEffect } from 'react';
import { firebaseConfig } from 'src/config';
import { useLocation } from 'react-router-dom'

import firebase from 'firebase/app'
import 'firebase/analytics'

// ----------------------------------------------------------------------


function FirebaseAnalytics({ children }) {
  const [analytics, setAnalytics] = React.useState(null)
  const location = useLocation()

  useEffect(() => {
    try {
      firebase.initializeApp(firebaseConfig)
      let tmpAnalytics = firebase.analytics()
      setAnalytics(tmpAnalytics)

      console.log("[firebase] loaded")
    } catch (err) {
      console.log('error loading firebase')
    }
  }, [])

  useEffect(() => {
    if (analytics) {
      let page_path = location.pathname + location.search;
      // analytics.setCurrentScreen(page_path);
      analytics.logEvent("page_view", { page_path });
    }

    window.Intercom("update");
  }, [location]);

  return null
}

export default FirebaseAnalytics;
