import Config from 'src/common/Config'
// import axios from 'src/utils/axios';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import {
  GET_SUMMARY_PENDING,
  GET_SUMMARY_SUCCESS,

  GET_SUMMARIES_FAST_PENDING,
  GET_SUMMARIES_FAST_SUCCESS,
  GET_SUMMARIES_PENDING,
  GET_SUMMARIES_SUCCESS,

  POST_SUMMARY_PENDING,
  POST_SUMMARY_SUCCESS,
  POST_SUMMARY_FAIL,

  EDIT_SUMMARY_PENDING,
  EDIT_SUMMARY_SUCCESS,
  EDIT_SUMMARY_FAIL,

  DELETE_SUMMARY_PENDING,
  DELETE_SUMMARY_SUCCESS,

  ADD_SUMMARIES_PAGE,
  ADD_ONE_USED
} from '../types'



export const dispachBothSummaries = (accessToken, page = 0, amount = 8, endFun = () => { }) => {
  return (dispatch) => {
    dispatch(getAllSummariesFast(accessToken, page, amount, endFun));
    dispatch(getAllSummaries(accessToken, page, amount, endFun));
  }
}

export const getAllSummariesFast = (accessToken, page = 0, amount = 8, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_SUMMARIES_FAST_PENDING })
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/get-summaries`,
      params: { page, amount, type: 'fast' },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SUMMARIES_FAST_SUCCESS, list: res.data.list, more: res.data.more })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
    })
  }
}

export const getAllSummaries = (accessToken, page = 0, amount = 8, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_SUMMARIES_PENDING })
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/get-summaries`,
      params: { page, amount },
      // params: { page: 0, amount: 0 },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SUMMARIES_SUCCESS, list: res.data.list, more: res.data.more })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
    })
  }
}

export const addPage = () => {
  return (dispatch) => dispatch({ type: ADD_SUMMARIES_PAGE })
}

export const getSummary = (accessToken, name, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/get-summary`,
      data: {
        name: name
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SUMMARY_SUCCESS, summary: res.data })
      endFun('success')
      return res.data
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}


export const editSummary = (accessToken, oldName, newName, newContent, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: EDIT_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/edit-summary`,
      data: {
        oldName,
        newName,
        content: newContent
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: EDIT_SUMMARY_SUCCESS, oldName, newName, content: newContent })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}


// export const deleteSummary = (accessToken, name, endFun = () => { }) => {
//   return (dispatch) => {
//     dispatch({ type: DELETE_SUMMARY_PENDING })
//     return axios({
//       method: 'post',
//       url: `${Config.apiBaseUrl}/storage/delete`,
//       data: {
//         filename: `summaries/${name}.json`
//       },
//       headers: {
//         'authorization': `Bearer ${accessToken}`
//       }
//     }).then(res => {

//       dispatch({ type: DELETE_SUMMARY_SUCCESS, name: name })
//       endFun('success')
//       return 'success'
//     }).catch(err => {
//       endFun('fail')
//       return 'fail'
//     })
//   }
// }
export const deleteSummary = (accessToken, name, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/delete-summary`,
      data: {
        name: name,
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: DELETE_SUMMARY_SUCCESS, name: name })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}


export const createNewSummary = (accessToken, name, filesList, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: POST_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/new-summary`,
      data: {
        name,
        filesList
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: ADD_ONE_USED })
      dispatch({ type: POST_SUMMARY_SUCCESS, summary: res.data })
      endFun('success')
      return res.data
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}