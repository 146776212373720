import {
  GET_ALL_FILES_PENDING,
  GET_ALL_FILES_SUCCESS,

  GET_FILE_LINK_PENDING,
  GET_FILE_LINK_SUCCESS,

  UPLOAD_FILE_PENDING,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,

  DELETE_FILE_PENDING,
  DELETE_FILE_SUCCESS,
  DELETE_ONE_FILE_SUCCESS,

  RENAME_FILE_PENDING,
  RENAME_FILE_SUCCESS,

  GET_STORAGE_SIZE_SUCCESS,
  RESET_FILES
} from '../types'

const initialState = {
  loading: false,
  error: null,

  files: [],
  library: {},
  size: 0.0,

  fileLink: null,

  getFileLinkLoading: false,
  uploadFilesLoading: false,
  deleteFilesLoading: false,
  renameFileLoading: false,

  uploadFilesError: null
};

export default (state = initialState, action) => {
  switch (action.type) {

    case RESET_FILES:
      {
        return {
          ...state,
          loading: true,
          error: null,
          files: [],
          library: {},
          size: 0,
          fileLink: null
        }
      }

    case GET_ALL_FILES_PENDING:
      {
        return {
          ...state,
          loading: true,
          error: null,
        }
      }
    case GET_ALL_FILES_SUCCESS:
      {

        return {
          ...state,
          loading: false,
          error: null,

          files: action.list,
          library: action.library,
        }
      }


    case GET_FILE_LINK_PENDING:
      {
        return {
          ...state,
          getFileLinkLoading: true
        }
      }
    case GET_FILE_LINK_SUCCESS:
      {

        return {
          ...state,
          getFileLinkLoading: false,
          fileLink: action.link
        }
      }


    case UPLOAD_FILE_PENDING:
      {
        return {
          ...state,
          uploadFilesLoading: true,
          uploadFilesError: null
        }
      }
    case UPLOAD_FILE_SUCCESS:
      {

        return {
          ...state,
          uploadFilesLoading: false,
          uploadFilesError: null
        }
      }
    case UPLOAD_FILE_FAIL:
      {

        return {
          ...state,
          uploadFilesLoading: false,
          uploadFilesError: action.error
        }
      }


    case DELETE_FILE_PENDING:
      {
        return {
          ...state,
          deleteFilesLoading: true
        }
      }
    case DELETE_FILE_SUCCESS:
      {
        return {
          ...state,
          deleteFilesLoading: false
        }
      }
    case DELETE_ONE_FILE_SUCCESS:
      {
        let newFiles = state.files.filter(f => f !== action.name)

        return {
          ...state,
          files: newFiles
        }
      }


    case RENAME_FILE_PENDING:
      {
        return {
          ...state,
          renameFileLoading: true
        }
      }
    case RENAME_FILE_SUCCESS:
      {
        // let newFiles = state.files.map(f => {
        //   if (f !== action.oldName) return f

        //   return action.newName
        // })

        return {
          ...state,
          renameFileLoading: false,
          // files: newFiles
        }
      }


    case GET_STORAGE_SIZE_SUCCESS:
      {

        return {
          ...state,
          size: action.size
        }
      }

    default:
      return state;
  }
};