import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';

import YouTube from 'react-youtube';

// ----------------------------------------------------------------------

function YoutubeDialog({ open, handleClose, title, videoId }) {

  const opts = {
    height: '500',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  if (window.innerHeight < 400) opts.height = '300'
  else if (window.innerHeight < 900) opts.height = '380'

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      {/* <YouTube videoId={videoId} opts={opts} />; */}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <YouTube videoId={videoId} opts={opts} />
      </DialogContent>
      {/* <DialogActions>
        <Button color="error" onClick={handleClose} variant="outlined">Close</Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default YoutubeDialog;
