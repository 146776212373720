import Config from 'src/common/Config'
import axios from 'src/utils/axios';

import {
  GET_ONEDRIVE_INFO_PENDING,
  GET_ONEDRIVE_INFO_SUCCESS,

  GET_ONEDRIVE_FILES_PENDING,
  GET_ONEDRIVE_FILES_SUCCESS
} from '../types'

const SOURCE = 'onedrive'

const mapFiles = (data, previousPath = '') => {
  let dirName = data.name ? `${data.name}` : ''
  let currentPath = previousPath !== '' ? `${previousPath}/${dirName}` : dirName
  let filesList = data.items
  filesList = filesList.filter(f => f !== null)

  let library = {}
  let allFiles = []

  let currentFiles = filesList.map(f => {
    if (typeof f === 'string') return { source: SOURCE, type: 'file', name: f, fullPath: data.name ? `${currentPath}/${f}` : f }

    let [tmpLib, tmpFiles] = mapFiles(f, currentPath)
    library = { ...library, ...tmpLib }
    allFiles = allFiles.concat(tmpFiles)

    return { source: SOURCE, type: 'folder', name: f.name, fullPath: data.name ? `${currentPath}/${f.name}` : f.name }
  })
  currentFiles = currentFiles.filter(f => f.name !== '')

  library[currentPath] = currentFiles
  allFiles = allFiles.concat(currentFiles)
  allFiles = allFiles.filter(f => f.type !== 'folder')

  return [library, allFiles]
}

export const checkOneDriveIntegration = (accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/onedrive/check`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch(getOneDriveInfo(accessToken))
      return res.data.active
    }).catch(err => {
      console.log(err)
      return 'fail'
    })
  }
}

export const getOneDriveInfo = (accessToken, path) => {
  return (dispatch) => {
    dispatch({ type: GET_ONEDRIVE_INFO_PENDING })

    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/onedrive/info`,
      params: {
        path
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_ONEDRIVE_INFO_SUCCESS, ...res.data })
      return 'success'
    }).catch(err => {
      console.log(err)
      return 'fail'
    })
  }
}

export const getOneDriveFiles = (accessToken, path) => {
  return (dispatch) => {
    dispatch({ type: GET_ONEDRIVE_FILES_PENDING })
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/onedrive/files`,
      params: {
        path
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      let [library, allFiles] = mapFiles(res.data)

      dispatch({ type: GET_ONEDRIVE_FILES_SUCCESS, list: allFiles, library: library })
      return allFiles
    }).catch(err => {
      dispatch({ type: GET_ONEDRIVE_FILES_SUCCESS, list: [], library: {} })
      return 'fail'
    })
  }
}

export const addOneDrive = (accessToken, code) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/onedrive/add-account`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      },
      data: {
        code: code
      }
    }).then(res => {

      dispatch({ type: GET_ONEDRIVE_INFO_SUCCESS, ...res.data })
      return res.data
    }).catch(err => {
      console.log(err)
      return err
    })
  }
}

export const uploadOneDrive = (accessToken, title, content) => {
  return axios({
    method: 'post',
    url: `${Config.apiBaseUrl}/onedrive/upload`,
    headers: {
      'authorization': `Bearer ${accessToken}`
    },
    data: {
      title,
      content,
    }
  }).then(res => {

    return 'success'
  }).catch(err => {
    return err
  })
}

export const downloadOneDriveFile = (accessToken, path) => {
  return axios({
    method: 'post',
    url: `${Config.apiBaseUrl}/onedrive/download`,
    headers: {
      'authorization': `Bearer ${accessToken}`
    },
    data: {
      path
    }
  }).then(res => {

    return res.data.link
  }).catch(err => {
    return null
  })
}