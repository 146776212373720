import Config from 'src/common/Config'
// import axios from 'src/utils/axios';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import Fingerprint2 from 'fingerprintjs2'
import { DeviceUUID } from 'device-uuid'

import { Base64 } from 'js-base64';

import {
  SET_DEVICE_ID,

  AUTH_LOGIN_PENDING,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,

  AUTH_SIGNUP_PENDING,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_FAIL,

  AUTH_RESET_PENDING,
  AUTH_RESET_SUCCESS,
  AUTH_RESET_FAIL,
  AUTH_UPDATE,
  AUTH_UPDATE_PENDING,

  AUTH_LOGOUT,

  RESET_AUTH_ERRORS,
  RESET_FILES,
  RESET_ORG,
  RESET_SUMMARIES
} from '../types'

const qs = require('qs')

const sections = ["TXVsdGktRG9tYWluIEF1dG9tYXRpYy", "BTZWFyY2ggU3VtbWFyaXphdGlvbi4="]

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return { valid: false, diff: -1 };
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  const diff = decoded.exp - currentTime

  return { valid: decoded.exp > currentTime, diff: diff }
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('@Brevi:accessToken', accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('@Brevi:accessToken');
    // delete axios.defaults.headers.common.Authorization;
  }
};

function getDeviceFingerprint() {
  return new Promise((resolve, reject) => {
    var du = new DeviceUUID().parse();
    Fingerprint2.get(function (components) {
      var keys = [
        'language',
        'platform',
        'hardwareConcurrency',
        'timezoneOffset',
        'webglVendorAndRenderer',
        'fonts',
        'screenResolution',
        'timezone',
        'canvas',
        'availableScreenResolution'
      ]
      var things = [
        du.platform,
        du.os,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isTouchScreen,
        du.silkAccelerated,
      ]
      for (var i = 0; i < components.length; i++) {

        if (keys.includes(components[i].key)) {
          var value = components[i].value
          if (Array.isArray(value)) {
            for (var j = 0; j < value.length; j++) {
              things.push(value[j])
            }
          }
          things.push(value)
        }
      }
      var uuid = du.hashMD5(things.join(':'));
      resolve(uuid)
    })
  })
}

function getRandom(max) {
  return Math.floor(Math.random() * Math.floor(max - 1)) + 1
}

function checkDigit(number) {
  var newNum = '' + number
  if (newNum.length === 1) return '0' + newNum

  return newNum
}

function encodeToken(token) {
  var newToken = Base64.encode(token)
  var positions = ''

  sections.map(section => {
    var divide = getRandom(section.length - 1)
    positions += checkDigit(divide)

    var tmp = [section.slice(0, divide), section.slice(divide)]
    tmp.map(str => {
      var place = getRandom(newToken.length - 1)
      positions += checkDigit(place)

      newToken = newToken.slice(0, place) + str + newToken.slice(place)
    })
  })

  newToken += positions
  return Base64.encode(newToken)
}

function decodeToken(token) {
  var newToken = Base64.decode(token)
  var positions = newToken.slice(newToken.length - 12).match(/.{1,2}/g).reverse()
  newToken = newToken.slice(0, newToken.length - 12)
  sections.reverse().map((section, index) => {
    var sectionPostions = positions.slice(0 + (index * 3), 3 + (index * 3))
    var divide = parseInt(sectionPostions[2])

    var tmp = [section.slice(divide), section.slice(0, divide)]
    tmp.map((str, index2) => {
      var place = sectionPostions[index2]
      newToken = newToken.slice(0, place) + newToken.slice(place).replace(str, '')
    })
  })

  var result = Base64.decode(newToken)
  return result
}

const loginFail = (err) => {
  return (dispatch) => {
    dispatch({
      type: AUTH_LOGIN_FAIL,
      error: err
    })
  }
}

export const setDeviceID = (deviceID) => {
  return (dispatch) => {
    dispatch({
      type: SET_DEVICE_ID,
      deviceID: deviceID
    })
  }
}

export const resetAuthErrors = () => {
  return (dispatch) => {
    dispatch({ type: RESET_AUTH_ERRORS })
  }
}

export const initAuth = (endFunction = () => { }) => {
  return async (dispatch) => {
    try {
      let deviceID = await localStorage.getItem('@Brevi:deviceID')
      getDeviceFingerprint()
      if (deviceID === null || deviceID === 'undefined') {
        deviceID = await getDeviceFingerprint()
        localStorage.setItem('@Brevi:deviceID', deviceID)
      }

      dispatch(setDeviceID(deviceID))

      const accessToken = await localStorage.getItem('@Brevi:accessToken')
      const { valid, diff } = isValidToken(accessToken)

      if (valid) {
        if (diff < 60 * 60) {
          var refreshToken = await localStorage.getItem('@Brevi:refreshToken')
          var checker = await localStorage.getItem('@Brevi:refreshTokenChecker')
          if (refreshToken !== null || refreshToken !== '' || refreshToken !== undefined || refreshToken !== 'undefined') {
            try {
              let decodedToken = false
              while (!decodedToken) {
                try {
                  let tmpRefreshToken = decodeToken(refreshToken)
                  if (tmpRefreshToken.startsWith(checker.slice(0, 3)) && tmpRefreshToken.endsWith(checker.slice(3))) {
                    refreshToken = tmpRefreshToken
                    decodedToken = true
                  }
                } catch (err) { }
              }
            } catch (err) {
              dispatch(logout(() => endFunction()))
            }

            dispatch(refreshTokenFun(refreshToken, () => endFunction()))
          }
          else dispatch(logout(() => endFunction()))
        }
        else {
          dispatch(updateUser(accessToken))
          setSession(accessToken)
          endFunction()
        }
      }
      else {
        dispatch(logout(() => endFunction()))
        endFunction()
      }
    } catch (err) {
      console.log(err)
      endFunction()
    }
  }
}

const loginForm = (email, password, remember = false, authCred = Config.Auth0Credentials) => {
  var form = {
    'grant_type': 'http://auth0.com/oauth/grant-type/password-realm',
    'client_id': authCred.clientId,
    // 'client_secret': authCred.clientSecret,
    username: email,
    password: password,
    audience: authCred.audience,
    realm: authCred.realm,
    scope: remember ? 'openid profile email offline_access' : 'openid profile email'
  }

  return axios({
    method: 'post',
    url: 'https://' + Config.Auth0Credentials.domain + '/oauth/token',
    data: qs.stringify(form),
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  }).then(res => {
    return res
  })
}

const dispatchLoginSuccess = (res, type = AUTH_LOGIN_SUCCESS, refreshing = false) => {
  return (dispatch) => {
    var data = jwtDecode(res.data.id_token)
    dispatch({
      type: type,
      accessToken: res.data.access_token,
      expiresAt: new Date(Date.now() + res.data.expires_in * 1000),
      email: data.email,
      username: data['https://brevi.app/username'],
      nickname: data.nickname,
      picture: data.picture,
      authID: data.sub,
      roles: data['https://brevi.app/roles'],
      org: data['https://brevi.app/app_metadata'].org,
      emailVerified: data.email_verified
    })

    setSession(res.data.access_token)

    if (res.data.refresh_token) {
      var encodedToken = encodeToken(res.data.refresh_token)
      localStorage.setItem('@Brevi:refreshToken', encodedToken)
      localStorage.setItem('@Brevi:refreshTokenChecker', `${res.data.refresh_token.slice(0, 3)}${res.data.refresh_token.slice(res.data.refresh_token.length - 3)}`)
    }
    else if (!refreshing) {
      localStorage.removeItem('@Brevi:refreshToken')
      localStorage.removeItem('@Brevi:refreshTokenChecker')
    }

    dispatch(updateUser(res.data.access_token))
  }
}

export const login = (email, password, remember = false, endFun = () => { }) => {
  return (dispatch) => {
    // dispatch({ type: AUTH_LOGIN_PENDING })

    return loginForm(email, password, remember).then(res => {
      dispatch(dispatchLoginSuccess(res, AUTH_LOGIN_SUCCESS))
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('error')
      console.log(err.response.data)
      dispatch(loginFail(err.response.data))

      return err.response.data
    })
  }
}

export const signUp = (email, password, username, orgName, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: AUTH_SIGNUP_PENDING })
    return axios({
      method: 'post',
      url: Config.apiBaseUrl + '/auth/signUp',
      data: {
        email: email,
        password: password,
        username: username,
        orgName: orgName
      }
    }).then(res => {
      if (res.data.error) {
        dispatch({ type: AUTH_SIGNUP_FAIL, signUpError: res.data.error })
        return
      }
      return loginForm(email, password, true).then(res => {
        dispatch({ type: AUTH_SIGNUP_SUCCESS })
        dispatch(dispatchLoginSuccess(res, AUTH_LOGIN_SUCCESS))
        endFun('success')
        return 'success'
      }).catch(err => {
        endFun('error')
        dispatch({ type: AUTH_SIGNUP_FAIL, signUpError: err.response.data })
      })
    }).catch(err => {
      endFun('error')
      dispatch({ type: AUTH_SIGNUP_FAIL, signUpError: err.response.data.error })
      return err.response?.data?.error || err.response
    })
  }
}

export const refreshTokenFun = (token, endFun = () => { }) => {
  return (dispatch) => {
    // let token;
    let authCred = Config.Auth0Credentials
    let type = AUTH_LOGIN_SUCCESS
    let form = {
      'grant_type': 'refresh_token',
      'client_id': authCred.clientId,
      'client_secret': authCred.clientSecret,
      'refresh_token': token
    }
    axios({
      method: 'post',
      url: 'https://' + authCred.domain + '/oauth/token',
      data: qs.stringify(form),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }).then(res => {
      dispatch(dispatchLoginSuccess(res, AUTH_LOGIN_SUCCESS, true))
      endFun()
    }).catch(err => {
      dispatch(logout(() => endFun()))
    })
  }
}

export const updateUser = (accessToken) => {
  return (dispatch) => {
    dispatch({ type: AUTH_UPDATE_PENDING })
    return axios({
      method: 'get',
      url: 'https://' + Config.Auth0Credentials.domain + '/userinfo',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      let data = res.data

      dispatch({
        type: AUTH_UPDATE,
        accessToken: accessToken,
        email: data.email,
        username: data['https://brevi.app/username'],
        nickname: data.nickname,
        picture: data.picture,
        authID: data.sub,
        roles: data['https://brevi.app/roles'],
        org: data['https://brevi.app/app_metadata'].org,
        emailVerified: data.email_verified
      })
      return 'success'
    }).catch(err => {
      console.log(err)
      dispatch(logout())
      return 'fail'
    })
  }
}

export const updateUserData = (accessToken, updateData) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/auth/update-user`,
      data: {
        updateData: updateData
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      return dispatch(updateUser(accessToken))
    }).catch(err => {
      return 'fail'
    })
  }
}

export const resetPassword = (email, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: AUTH_RESET_PENDING })
    return axios({
      method: 'post',
      url: 'https://' + Config.Auth0Credentials.domain + '/dbconnections/change_password',
      data: {
        client_id: Config.Auth0Credentials.clientId,
        email: email,
        connection: Config.Auth0Credentials.realm
      },
    }).then(res => {
      dispatch({ type: AUTH_RESET_SUCCESS, message: res.data })
      endFun("done")
      return 'success'
    }).catch(err => {
      if (err.data) {
        console.log('failed to reset', err.data)
        dispatch({ type: AUTH_RESET_FAIL, message: err.data.error_description })
      }
      else {
        dispatch({ type: AUTH_RESET_FAIL, message: "Faled to reset" })
      }
      endFun("error")
    })
  }
}

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem('@Brevi:refreshToken')
    localStorage.removeItem('@Brevi:refreshTokenChecker')
    localStorage.removeItem('@Brevi:accessToken')
    dispatch({ type: AUTH_LOGOUT })
    dispatch({ type: RESET_FILES })
    dispatch({ type: RESET_ORG })
    dispatch({ type: RESET_SUMMARIES })
  }
}

export const deleteAccont = (accessToken) => {

  return (dispatch) => {

    return axios({
      method: 'post',
      url: Config.apiBaseUrl + '/auth/delete-user',
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      dispatch(logout())
      return 'success'
    }).catch(err => {
      return 'fail'
    })
  }
}

export const sendEvent = (accessToken, deviceID, eventType, eventId, category, startDate, endDate) => {
  return (dispatch) => {

    axios({
      method: 'post',
      url: Config.apiBaseUrl + '/event',
      data: {
        userId: deviceID,
        eventType: eventType,
        eventId: eventId,
        category: category,
        startDate: startDate,
        endDate: endDate
      },
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    })
  }
}