import Config from 'src/common/Config'
import axios from 'src/utils/axios';
import { orderBy } from 'lodash';

import {
  GET_BLOGS_PENDING,
  GET_BLOGS_SUCCESS
} from '../types'

export const getBlogs = () => {
  return (dispatch) => {
    dispatch({ type: GET_BLOGS_PENDING })

    axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/blogs`
    }).then(res => {
      let blogList = res.data.list
      blogList = orderBy(blogList, ['date'], ['desc']);

      let alwaysFirst;
      blogList = blogList.filter(b => {
        if (b.link === 'brevi-assistant-your-quick-start-guide') {
          alwaysFirst = b
          return false
        }

        return true
      })
      if (alwaysFirst) blogList = [alwaysFirst].concat(blogList)

      dispatch({ type: GET_BLOGS_SUCCESS, blogs: blogList })
    }).catch(err => {
      setTimeout(() => {
        console.log(err)
        dispatch(getBlogs())
      }, 2000)
    })
  }
}

export const getBlog = (blogLink) => {
  return axios({
    method: 'get',
    url: `${Config.apiBaseUrl}/blog?id=${blogLink}`
  }).then(res => {
    return res.data
  }).catch(err => {
    return 'fail'
  })
}