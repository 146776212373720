import Config from 'src/common/Config'
// import axios from 'src/utils/axios';
import axios from 'axios';

import {
  GET_ALL_FILES_PENDING,
  GET_ALL_FILES_SUCCESS,

  GET_FILE_LINK_PENDING,
  GET_FILE_LINK_SUCCESS,

  UPLOAD_FILE_PENDING,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,

  DELETE_FILE_PENDING,
  DELETE_FILE_SUCCESS,
  DELETE_ONE_FILE_SUCCESS,

  RENAME_FILE_PENDING,
  RENAME_FILE_SUCCESS,

  GET_STORAGE_SIZE_SUCCESS,
} from '../types'

const PASS_FOLDERS = ['.tmp-single']

const mapFiles = (data, previousPath = '') => {
  if (PASS_FOLDERS.includes(data.name)) return [{}, []]
  let dirName = data.name ? `${data.name}` : ''
  let currentPath = previousPath !== '' ? `${previousPath}/${dirName}` : dirName
  let filesList = data.items

  let library = {}
  let allFiles = []

  let currentFiles = filesList.map(f => {
    if (typeof f === 'string') return { type: 'file', name: f, fullPath: data.name ? `${currentPath}/${f}` : f }

    let [tmpLib, tmpFiles] = mapFiles(f, currentPath)
    library = { ...library, ...tmpLib }
    allFiles = allFiles.concat(tmpFiles)

    return { type: 'folder', name: f.name, fullPath: data.name ? `${currentPath}/${f.name}` : f.name }
  })
  currentFiles = currentFiles.filter(f => {
    if (f.type === 'folder' && PASS_FOLDERS.includes(f.name)) return false
    
    return f.name !== ''
  })

  library[currentPath] = currentFiles
  allFiles = allFiles.concat(currentFiles)
  allFiles = allFiles.filter(f => f.type !== 'folder')

  return [library, allFiles]
}

export const initStorage = (accessToken, endFun = () => { }) => {
  return (dispatch) => {
    dispatch(getAllFiles(accessToken, endFun))
    dispatch(getStorageSize(accessToken, endFun))
  }
}

export const getAllFiles = (accessToken, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_FILES_PENDING })
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/storage/all`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      // let list = res.data.items.map(f => {
      //   if (typeof f === 'string') return { name: f, fullPath: f }
      //   return null
      // })
      // list = list.filter(f => f !== null)
      let [library, allFiles] = mapFiles(res.data)

      dispatch({ type: GET_ALL_FILES_SUCCESS, list: allFiles, library: library })
      endFun('success')
      return allFiles
    }).catch(err => {
      endFun('fail')
    })
  }
}

export const getFileLink = (accessToken, filename, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_FILE_LINK_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/storage/link`,
      data: {
        filename
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_FILE_LINK_SUCCESS, link: res.data })
      endFun('success')
      return res.data
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}

export const startUploadingFiles = () => {
  return (dispatch) => {
    dispatch({ type: UPLOAD_FILE_PENDING })
  }
}

export const endUploadingFiles = () => {
  return (dispatch) => {
    dispatch({ type: UPLOAD_FILE_SUCCESS })
  }
}

export const uploadFile = (accessToken, file, endFun = () => { }) => {
  return (dispatch) => {
    const formData = new FormData()
    formData.append('file', file)

    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/storage/upload`,
      data: formData,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}

export const startDeletingFiles = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_FILE_PENDING })
  }
}

export const endDeletingFiles = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_FILE_SUCCESS })
  }
}

export const deleteFile = (accessToken, filename, endFun = () => { }) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/storage/delete`,
      data: {
        filename
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: DELETE_ONE_FILE_SUCCESS, name: filename })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}

export const deleteFolder = (accessToken, folderName, endFun = () => { }) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/storage/delete-folder`,
      data: {
        folderName
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      // dispatch({ type: DELETE_ONE_FILE_SUCCESS, name: folderName })/
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}


export const createFolder = (accessToken, folderName, endFun = () => { }) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/storage/new-folder`,
      data: {
        folderName: folderName
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(async res => {

      await dispatch(getAllFiles(accessToken))

      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}

export const renameFile = (accessToken, oldName, newName, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: RENAME_FILE_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/storage/rename`,
      data: {
        oldName,
        newName
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(async res => {

      // await dispatch(getAllFiles(accessToken))

      dispatch({ type: RENAME_FILE_SUCCESS, oldName, newName })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}

export const getStorageSize = (accessToken, endFun = () => { }) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/storage/size`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      let gb = parseFloat(res.data.size) / 1000

      dispatch({ type: GET_STORAGE_SIZE_SUCCESS, size: gb })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}