import 'lazysizes';
// import 'src/_api_';
// import 'src/utils/i18n';
import 'src/utils/highlight';
import 'simplebar/src/simplebar.css';
// import 'mapbox-gl/dist/mapbox-gl.css';
import 'slick-carousel/slick/slick.css';
// import 'react-image-lightbox/style.css';
// import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick-theme.css';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'src/assets/css/main.css'
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';

// ----------------------------------------------------------------------

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    window.Intercom("boot", {
      app_id: "ctzzkrlu"
    });
  }
};

ReactDOM.render(<App />, document.getElementById('root'));
