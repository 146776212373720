let config = {

  apiBaseUrl: 'https://api.brevi.app',
  // apiBaseUrl: 'http://localhost:1337',
  // apiBaseUrl: 'http://localhost:8080',

  Auth0Anonymous: {
    domain: 'brevi-assistant.us.auth0.com',
    clientId: 'Zai1dymezFggdYswGjBP0BTyPJty52kQ',
    clientSecret: 'RayTo_hVrWpR-jgkn4m8dcX295I_AAWbN2wHmNVOhtKeTaZVsRWlXcp-_zCEGfxB',
    realm: 'Temporary-Users',
    audience: 'https://api.plex.page'
  },

  Auth0Credentials: {
    resetDomain: 'brevi-assistant.us.auth0.com',
    // domain: 'auth.knowledge.page',
    domain: 'brevi-assistant.us.auth0.com',
    clientId: 'JfSZt1Jc2gULV7THbEFLVcJRNF2sdWZa',
    clientSecret: 'SZaB8ibj3CGLCwkItkBf0L9D4yY6DumU2Q-7ldxp5J5tnt9wQYrbc_gKTe6avXjo',
    realm: 'Brevi-Assistant-Users',
    audience: 'https://api.brevi.app'
  },

  singleSummariesMultiplier: 3,

  fileSourceSeperator: ":://",
  researchPapersSavePath: 'My Research Papers',
  scienceInformationSavePath: 'My Science',
  healthcareInformationSavePath: 'My Healthcare',

  plans: [
    {
      name: 'tier 1',
      icon: '/static/icons/ic_plan_free.svg',
      price: 50.00,
      yearlyPrice: 40.00,
      yearlyTotal: 480,
      caption: 'saving $120 a year',
      lists: [
        { text: '100 - multi-document summary generations per month', isAvailable: true },
        { text: '300 - single-document summary generations per month', isAvailable: true },
        { text: '16 GB - Storage (~ 400,000 documents)', isAvailable: true },
        { text: 'Unlimited team members', isAvailable: true },
        { text: 'Fast & easy support', isAvailable: true },
        { text: `Unlimited research papers search`, isAvailable: true },
        { text: 'Share integrations - Slack and more to come', isAvailable: true },
        { text: 'Storage integrations - OneDrive and Google Drive', isAvailable: true },
      ],
      labelAction: 'choose tier 1',
      summaries: 100,
      maxGb: 16,
      // paypal: { // SANDBOX
      //   monthly: 'P-8FP136668A749221UMCGGCLA', 
      //   yearly: 'P-33G70102BL071450XMCGGC7Y'
      // },
      paypal: {
        monthly: 'P-3B172120SN702350EMCHPFLA',
        yearly: 'P-30X91649K0071083KMCHPFUI'
      }
    },
    {
      name: 'tier 2',
      icon: '/static/icons/ic_plan_starter.svg',
      price: 200.00,
      yearlyPrice: 160,
      yearlyTotal: 1920,
      caption: 'saving $480 a year',
      lists: [
        { text: '400 - multi-document generations per month', isAvailable: true },
        { text: '1200 - single-document generations per month', isAvailable: true },
        { text: '32 GB - Storage (~ 800,000 documents)', isAvailable: true },
        { text: 'Unlimited team members', isAvailable: true },
        { text: 'Fast & easy support', isAvailable: true },
        { text: `Unlimited research papers search`, isAvailable: true },
        { text: 'Share integrations - Slack and more to come', isAvailable: true },
        { text: 'Storage integrations - OneDrive and Google Drive', isAvailable: true },
      ],
      labelAction: 'choose tier 2',
      summaries: 400,
      maxGb: 32,
      // paypal: { // SANDBOX
      //   monthly: 'P-0NT93856DG254940BMCGGD7I',
      //   yearly: 'P-9FY108042C3309609MCGGDSA'
      // },
      paypal: {
        monthly: 'P-9T853892CP285311NMCHPAWQ',
        yearly: 'P-8JN5351051924961BMCHPA5A'
      },
    },
    {
      name: 'tier 3',
      icon: '/static/icons/ic_plan_premium.svg',
      price: 500.00,
      yearlyPrice: 400.00,
      yearlyTotal: 4800,
      caption: 'saving $1200 a year',
      lists: [
        { text: '1000 - multi-document generations per month', isAvailable: true },
        { text: '3000 - single-document generations per month', isAvailable: true },
        { text: '64 GB - Storage (~ 1,600,000 documents)', isAvailable: true },
        { text: 'Unlimited team members', isAvailable: true },
        { text: 'Fast & easy support', isAvailable: true },
        { text: `Unlimited research papers search`, isAvailable: true },
        { text: 'Share integrations - Slack and more to come', isAvailable: true },
        { text: 'Storage integrations - OneDrive and Google Drive', isAvailable: true },
      ],
      labelAction: 'choose tier 3',
      summaries: 1000,
      maxGb: 64,
      // paypal: { // SANDBOX
      //   monthly: 'P-4PK79737UP364913HMCGGELI',
      //   yearly: 'P-2AD626091S972864UMCGGEZI'
      // },
      paypal: {
        monthly: 'P-70V38070LW0026254MCHPAQI',
        yearly: 'P-34253061C4309060LMCHPAKI'
      },
    }
  ],

  payAsYouGoPrice: 0.5,

  rates: [
    [1001, 48, 0.5],
    [500, 24, 0.55],
    [200, 8, 0.55],
    [100, 4, 0.6],
    [50, 2, 0.6],
  ]
}

export default config