import NavBar from './NavBar';
import TopBar from './TopBar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/core/Alert'
import Container from '@material-ui/core/Container'

import {
  varFadeInRight,
  MotionInView
} from 'src/components/Animate';

import { initStorage, getOrgInfo, getSlackInfo, checkOneDriveIntegration, checkGoogleDriveIntegration } from 'src/redux/actions'

// ----------------------------------------------------------------------

const NOTICE_TEXT = `On June 20th we will launch a new version of the summarization model. It will make the summaries even more accurate, smooth and easy to read. Also stay tuned for the Slack, Google Drive and Microsoft OneDrive integrations.`

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
  },
  main: {
    position: 'relative',
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 40,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 40,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node
};

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [openNav, setOpenNav] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch()
  const accessToken = useSelector(state => state.auth.accessToken)

  React.useEffect(() => {
    dispatch(getOrgInfo(accessToken))
    // dispatch(initStorage(accessToken))
    dispatch(getSlackInfo(accessToken))
    dispatch(checkOneDriveIntegration(accessToken))
    dispatch(checkGoogleDriveIntegration(accessToken))
  }, [dispatch])

  React.useEffect(() => {
    // setTimeout(() => setShowAlert(true), 1500)
    // setShowAlert(true)
  }, [])

  return (
    <div className={classes.root}>
      <TopBar onOpenNav={() => setOpenNav(true)} />
      <NavBar onCloseNav={() => setOpenNav(false)} isOpenNav={openNav} />

      <div className={classes.main}>
        {showAlert &&
          <MotionInView variants={varFadeInRight}>
            <Container sx={{ mb: 1.5 }}>
              <Alert variant="outlined" severity="info" onClose={() => setShowAlert(false)}>
                <strong>Notice:</strong> {NOTICE_TEXT}
              </Alert>
            </Container>
          </MotionInView>
        }

        {children}
      </div>
    </div>
  );
}

export default DashboardLayout;
