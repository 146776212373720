import {
  GET_SINGLE_SUMMARY_PENDING,
  GET_SINGLE_SUMMARY_SUCCESS,

  GET_SINGLE_SUMMARIES_FAST_PENDING,
  GET_SINGLE_SUMMARIES_FAST_SUCCESS,
  GET_SINGLE_SUMMARIES_PENDING,
  GET_SINGLE_SUMMARIES_SUCCESS,

  POST_SINGLE_SUMMARY_PENDING,
  POST_SINGLE_SUMMARY_SUCCESS,
  POST_SINGLE_SUMMARY_FAIL,

  EDIT_SINGLE_SUMMARY_PENDING,
  EDIT_SINGLE_SUMMARY_SUCCESS,
  EDIT_SINGLE_SUMMARY_FAIL,

  DELETE_SINGLE_SUMMARY_PENDING,
  DELETE_SINGLE_SUMMARY_SUCCESS,

  ADD_SINGLE_SUMMARIES_PAGE,
  RESET_SINGLE_SUMMARIES,
} from '../types'

const initialState = {
  fastLoading: false,
  fastError: null,

  fastSummaries: [],

  loading: false,
  error: null,

  summaries: [],
  summary: null,

  hasMore: true,
  amount: 8,
  page: 0,

  editSummaryLoading: false,
  deleteSummaryLoading: false,
  newSummaryLoading: false,
  singleLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {

    case RESET_SINGLE_SUMMARIES:
      {
        return {
          ...state,
          loading: true,
          error: null,
          summaries: [],
          summary: null,
          hasMore: true
        }
      }
    case GET_SINGLE_SUMMARIES_FAST_PENDING:
      {
        return {
          ...state,
          fastLoading: true,
          fastError: null,
        }
      }
    case GET_SINGLE_SUMMARIES_FAST_SUCCESS:
      {

        return {
          ...state,
          fastLoading: false,
          fastError: null,
          fastSummaries: action.list,
        }
      }
    case GET_SINGLE_SUMMARIES_PENDING:
      {
        return {
          ...state,
          loading: true,
          error: null,
        }
      }
    case GET_SINGLE_SUMMARIES_SUCCESS:
      {

        return {
          ...state,
          loading: false,
          error: null,
          summaries: action.list,
          hasMore: action.more
        }
      }
    case ADD_SINGLE_SUMMARIES_PAGE:
      {

        return {
          ...state,
          page: state.page + 1
        }
      }


    case GET_SINGLE_SUMMARY_PENDING:
      {
        return {
          ...state,
          singleLoading: true
        }
      }
    case GET_SINGLE_SUMMARY_SUCCESS:
      {

        return {
          ...state,
          singleLoading: false,
          error: null,
          summary: action.summary
        }
      }


    case EDIT_SINGLE_SUMMARY_PENDING:
      {
        return {
          ...state,
          editSummaryLoading: true
        }
      }
    case EDIT_SINGLE_SUMMARY_SUCCESS:
      {
        let newSummaries = state.summaries.map(s => {
          if (s.name !== action.oldName) return s

          return {
            name: action.newName,
            content: action.content,
            last_updated: new Date().toISOString()
          }
        })

        let newFastSummaries = state.fastSummaries.map(s => {
          if (s.name !== action.oldName) return s

          return {
            name: action.newName,
          }
        })

        return {
          ...state,
          editSummaryLoading: false,
          summaries: newSummaries,
          fastSummaries: newFastSummaries
        }
      }



    case DELETE_SINGLE_SUMMARY_PENDING:
      {
        return {
          ...state,
          deleteSummaryLoading: true
        }
      }
    case DELETE_SINGLE_SUMMARY_SUCCESS:
      {
        let newSummaries = state.summaries.filter(s => s.name !== action.name)
        let newFastSummaries = state.fastSummaries.filter(s => s.name !== action.name)

        return {
          ...state,
          deleteSummaryLoading: false,
          summaries: newSummaries,
          fastSummaries: newFastSummaries
        }
      }


    case POST_SINGLE_SUMMARY_PENDING:
      {
        return {
          ...state,
          newSummaryLoading: true,
          newPost: null
        }
      }
    case POST_SINGLE_SUMMARY_SUCCESS:
      {
        let newPost = {
          name: action.summary.name,
          content: action.summary.content,
          last_updated: new Date().toISOString()
        }

        let newSummaries = state.summaries
        newSummaries.push(newPost)

        return {
          ...state,
          newSummaryLoading: false,
          summaries: newSummaries,
          newPost: newPost
        }
      }
    default:
      return state;
  }
};

const defaultAppMetadata = {
  counter: 0,
  maxCounter: 3,
  plan: {
    ads: true,
    download: false,
    endDate: '---',
    name: 'Basic',
    paymentMethod: '---',
    queue: false
  },
  stripeCustomerID: null,
  downloadCounter: 0,
  subscription: {
    status: 'INACTIVE',
    subscriptionID: '',
    type: ''
  },
  paymentHistory: []
}