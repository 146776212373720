import {
  GET_BLOGS_PENDING,
  GET_BLOGS_SUCCESS
} from '../types'

const initialState = {
  blogsLoading: false,
  lastUpdated: "2019-11-05T17:24:21.099Z",
  blogs: []
};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_BLOGS_PENDING:
      {
        return {
          ...state,
          blogsLoading: true,
        }
      }

    case GET_BLOGS_SUCCESS:
      {
        return {
          ...state,
          blogsLoading: false,
          blogs: action.blogs,
          lastUpdated: new Date().toISOString()
        }
      }

    default:
      return state;
  }
}