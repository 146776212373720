import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { login as jwtLogin, register as jwtRegister, logout as jwtLogout } from 'src/redux/slices/authJwt';
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore
} from 'react-redux-firebase';

import { login, signUp, logout, resetPassword } from 'src/redux/actions'

// ----------------------------------------------------------------------

function getRole(roles) {
  if (!roles) return 'viewer'

  // let role = ''
  switch (roles.length) {
    case 1: {
      return 'viewer'
    }
    case 2: {
      return 'editor'
    }
    case 3: {
      return 'admin'
    }
    case 4: {
      return 'owner'
    }

    default: {
      return 'viewer'
    }
  }

  // return role
}

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase', 'auth0'])
};

export default function useAuth(method = 'auth0') {
  // Firebase Auth
  // const firebase = useFirebase();
  // const firestore = useFirestore();
  // const { auth, profile } = useSelector((state) => state.firebase);

  // JWT Auth
  const dispatch = useDispatch();
  // const { user, isLoading, isAuthenticated } = useSelector(
  //   (state) => state.authJwt
  // );

  const authState = useSelector((state) => state.auth);

  // JWT Auth
  // if (method === 'jwt') {
  //   return {
  //     method: 'jwt',
  //     user: user,
  //     isLoading: isLoading,
  //     isAuthenticated: isAuthenticated,

  //     login: ({ email, password }) =>
  //       dispatch(
  //         jwtLogin({
  //           email: email,
  //           password: password
  //         })
  //       ),

  //     register: ({ email, password, firstName, lastName }) =>
  //       dispatch(
  //         jwtRegister({
  //           email: email,
  //           password: password,
  //           firstName: firstName,
  //           lastName: lastName
  //         })
  //       ),

  //     logout: () => dispatch(jwtLogout()),

  //     resetPassword: () => { },

  //     updateProfile: () => { }
  //   };
  // }

  // else if (method === 'auth0') {
  return {
    method: 'auth0',
    user: {
      displayName: authState.nickname || '',
      username: authState.username || '',
      email: authState.email || '',
      emailVerified: authState.emailVerified || false,
      photoURL: authState.picture || '',
      roles: authState.roles || [],
      org: authState.org || '',
      role: getRole(authState.roles),

      phoneNumber: authState.phoneNumber || '',
      country: authState.country || '',
      address: authState.address || '',
      state: authState.state || '',
      city: authState.city || '',
      zipCode: authState.zipCode || '',
      about: authState.about || '',
      isPublic: authState.isPublic || false
    },
    isLoading: authState.loading,
    isAuthenticated: authState.isLoggedIn,

    accessToken: authState.accessToken,

    login: ({ email, password, remember = false, endFun }) => dispatch(login(email, password, remember, endFun)),

    register: ({ email, password, username, orgName, endFun }) => dispatch(signUp(email, password, username, orgName, endFun)),

    logout: (endFun) => dispatch(logout(endFun)),

    resetPassword: (email) => dispatch(resetPassword(email)),

    updateProfile: () => { }
  }

  // Firebase Auth
  // return {
  //   method: 'firebase',
  //   user: {
  //     displayName: auth.displayName || profile.displayName || '',
  //     email: auth.email || '',
  //     photoURL: auth.photoURL || profile.photoURL || '',
  //     phoneNumber: auth.phoneNumber || profile.phoneNumber || '',
  //     country: profile.country || '',
  //     address: profile.address || '',
  //     state: profile.state || '',
  //     city: profile.city || '',
  //     zipCode: profile.zipCode || '',
  //     about: profile.about || '',
  //     role: profile.role || '',
  //     isPublic: profile.isPublic || false
  //   },
  //   isLoading: !isLoaded(auth),
  //   isAuthenticated: !isEmpty(auth),

  //   login: ({ email, password }) =>
  //     firebase.login({
  //       email: email,
  //       password: password
  //     }),
  //   loginWithGoogle: () =>
  //     firebase.login({ provider: 'google', type: 'popup' }),

  //   loginWithFaceBook: () =>
  //     firebase.login({ provider: 'facebook', type: 'popup' }),

  //   loginWithTwitter: () =>
  //     firebase.login({ provider: 'twitter', type: 'popup' }),

  //   register: ({ email, password, firstName, lastName }) =>
  //     firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(email, password)
  //       .then((res) => {
  //         firestore
  //           .collection('users')
  //           .doc(res.user.uid)
  //           .set({
  //             uid: res.user.uid,
  //             email: email,
  //             displayName: firstName + ' ' + lastName
  //           });
  //       }),

  //   logout: () => firebase.logout(),

  //   resetPassword: (email) => firebase.resetPassword(email),

  //   updateProfile: ({
  //     displayName,
  //     photoURL,
  //     phoneNumber,
  //     country,
  //     state,
  //     city,
  //     address,
  //     zipCode,
  //     about,
  //     isPublic
  //   }) =>
  //     firebase.updateProfile({}).then((res) => {
  //       firestore.collection('users').doc(res.id).set(
  //         {
  //           displayName: displayName,
  //           photoURL: photoURL,
  //           phoneNumber: phoneNumber,
  //           country: country,
  //           state: state,
  //           city: city,
  //           address: address,
  //           zipCode: zipCode,
  //           about: about,
  //           isPublic: isPublic
  //         },
  //         { merge: true }
  //       );
  //     })
  // };
}
