import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import HomeLayout from 'src/layouts/HomeLayout';
import { PATH_PAGE } from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('src/views/home/LandingPageView'))
    },
    {
      exact: true,
      path: '/use-cases',
      component: lazy(() => import('src/views/home/UseCasesView'))
    },
    {
      exact: true,
      path: PATH_PAGE.blog,
      component: lazy(() => import('src/views/home/BlogListView'))
    },
    {
      exact: true,
      path: `${PATH_PAGE.blog}/:blogId`,
      component: lazy(() => import('src/views/home/BlogDetailsView'))
    },
    {
      exact: true,
      path: PATH_PAGE.pricing,
      component: lazy(() => import('src/views/pages/PricingView'))
    },
    {
      exact: true,
      path: PATH_PAGE.privacyPolicy,
      component: lazy(() => import('src/views/pages/PrivacyPolicyView'))
    },
    {
      exact: true,
      path: PATH_PAGE.termsAndConditions,
      component: lazy(() => import('src/views/pages/TermsAndConditionsView'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
