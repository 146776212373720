import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import HomeLayout from 'src/layouts/HomeLayout';
import { PATH_PAGE } from './paths';

// ----------------------------------------------------------------------

const OtherRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: PATH_PAGE.privacyPolicy,
      component: lazy(() => import('src/views/pages/PrivacyPolicyView'))
    },
    {
      exact: true,
      path: PATH_PAGE.pricing,
      component: lazy(() => import('src/views/pages/PricingView'))
    },
  ]
};

export default OtherRoutes;
