import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardProtect from 'src/components/Auth/DashboardProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: DashboardProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.general.dashboard,
    //   component: lazy(() => import('src/views/general/DashboardAppView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.general.ecommerce,
    //   component: lazy(() => import('src/views/general/DashboardEcommerceView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.general.analytics,
    //   component: lazy(() => import('src/views/general/DashboardAnalyticsView'))
    // },

    // GENERAL : Summaries
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.summarize.root,
      component: lazy(() => import('src/views/summarize/SummarizeView'))
    },
    {
      exact: true,
      path: PATH_APP.general.summarize.edit,
      component: lazy(() => import('src/views/summarize/EditSummaryView'))
    },
    {
      exact: true,
      path: PATH_APP.general.summarize.new,
      component: lazy(() => import('src/views/summarize/NewSummaryView'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.summarize.root} />
    },
    // GENERAL : Single Summaries
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.singleSummarize.root,
      component: lazy(() => import('src/views/singleSummaries/SummarizeView'))
    },
    {
      exact: true,
      path: PATH_APP.general.singleSummarize.edit,
      component: lazy(() => import('src/views/singleSummaries/EditSummaryView'))
    },
    {
      exact: true,
      path: PATH_APP.general.singleSummarize.new,
      component: lazy(() => import('src/views/singleSummaries/NewSummaryView'))
    },
    // LIBRARY
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.library.library,
      component: lazy(() => import('src/views/library/LibraryView'))
    },
    {
      exact: true,
      path: PATH_APP.library.upload,
      component: lazy(() => import('src/views/library/UploadView'))
    },
    {
      exact: true,
      path: PATH_APP.library.researchPapers.root,
      component: lazy(() => import('src/views/library/FIndResearchPapersView'))
    },
    {
      exact: true,
      path: PATH_APP.library.researchPapers.list,
      component: lazy(() => import('src/views/library/FRPAutomationList'))
    },
    {
      exact: true,
      path: PATH_APP.library.researchPapers.create,
      component: lazy(() => import('src/views/library/FRPAutomationCreate'))
    },
    {
      exact: true,
      path: PATH_APP.library.exploreScience.root,
      component: lazy(() => import('src/views/library/ExploreScienceEducation/FindScienceEducation'))
    },
    {
      exact: true,
      path: PATH_APP.library.exploreScience.list,
      component: lazy(() => import('src/views/library/ExploreScienceEducation/AutomationList'))
    },
    {
      exact: true,
      path: PATH_APP.library.exploreScience.create,
      component: lazy(() => import('src/views/library/ExploreScienceEducation/AutomationCreate'))
    },
    {
      exact: true,
      path: PATH_APP.library.searchHealthcare.root,
      component: lazy(() => import('src/views/library/HealthCareInformation/FindHealthCare'))
    },
    {
      exact: true,
      path: PATH_APP.library.searchHealthcare.list,
      component: lazy(() => import('src/views/library/HealthCareInformation/AutomationList'))
    },
    {
      exact: true,
      path: PATH_APP.library.searchHealthcare.create,
      component: lazy(() => import('src/views/library/HealthCareInformation/AutomationCreate'))
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.user.account,
      component: lazy(() => import('src/views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.list,
      component: lazy(() => import('src/views/user/UserListView'))
    },

    // MANAGEMENT : OTHER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.billing,
      component: lazy(() => import('src/views/management/BillingView'))
    },
    {
      exact: true,
      path: PATH_APP.management.integrations,
      component: lazy(() => import('src/views/management/IntegrationsView'))
    },
    // {
    //   exact: true,
    //   path: PATH_APP.management.user.profile,
    //   component: lazy(() => import('src/views/user/ProfileView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.user.cards,
    //   component: lazy(() => import('src/views/user/UserCardsView'))
    // },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.account} />
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: () => <Redirect to={PATH_APP.management.user.account} />
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
