import {
  GET_AUTOMATIONS_PENDING,
  GET_AUTOMATIONS_SUCCESS
} from '../types'

const initialState = {
  loading: false,
  automations: {}
};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_AUTOMATIONS_PENDING:
      {
        return {
          ...state,
          loading: true,
        }
      }

    case GET_AUTOMATIONS_SUCCESS:
      {
        return {
          ...state,
          loading: false,
          automations: action.automations,
        }
      }

    default:
      return state;
  }
}