import {
  SET_DEVICE_ID,

  AUTH_LOGIN_PENDING,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,

  AUTH_SIGNUP_PENDING,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_FAIL,

  AUTH_RESET_PENDING,
  AUTH_RESET_SUCCESS,
  AUTH_RESET_FAIL,

  AUTH_LOGOUT,
  AUTH_UPDATE,
  AUTH_UPDATE_PENDING,

  RESET_AUTH_ERRORS
} from '../types'

const initialState = {
  loading: false,
  error: null,
  isLoggedIn: false,
  deviceID: null,

  accessToken: null,
  expiresAt: null,

  email: '',
  emailVerified: false,
  username: '',
  nickname: '',
  picture: '',
  authID: '',

  roles: [],
  org: '',

  resetMessage: null,
  resetError: null,

  signUpError: null,
  hasSignedUp: false,

  authUpdateLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_DEVICE_ID:
      {
        return {
          ...state,
          deviceID: action.deviceID
        }
      }
    case AUTH_LOGIN_PENDING:
      {
        return {
          ...state,
          loading: true
        }
      }
    case AUTH_LOGIN_SUCCESS:
      {
        var newExpiresAt = state.expiresAt
        if (action.expiresAt) {
          newExpiresAt = action.expiresAt
        }

        return {
          ...state,
          loading: false,
          isLoggedIn: true,
          error: null,
          accessToken: action.accessToken,
          expiresAt: newExpiresAt,
          email: action.email,
          emailVerified: action.emailVerified,
          username: action.username,
          nickname: action.nickname,
          picture: action.picture,
          authID: action.authID,
          roles: action.roles,
          org: action.org
        }
      }
    case AUTH_LOGIN_FAIL:
      {
        return {
          ...state,
          loading: false,
          isLoggedIn: false,
          error: action.error
        }
      }
    case AUTH_UPDATE_PENDING:
      {
        return {
          ...state,
          authUpdateLoading: true
        }
      }
    case AUTH_UPDATE:
      {
        var newExpiresAt = state.expiresAt
        if (action.expiresAt) {
          newExpiresAt = action.expiresAt
        }

        return {
          ...state,
          loading: false,
          authUpdateLoading: false,
          isLoggedIn: true,
          error: null,
          accessToken: action.accessToken,
          expiresAt: newExpiresAt,
          email: action.email,
          emailVerified: action.emailVerified,
          username: action.username,
          nickname: action.nickname,
          picture: action.picture,
          authID: action.authID,
          roles: action.roles,
          org: action.org
        }
      }
    case AUTH_SIGNUP_PENDING:
      {
        return {
          ...state,
          hasSignedUp: false,
          signUpError: null
        }
      }
    case AUTH_SIGNUP_SUCCESS:
      {
        return {
          ...state,
          hasSignedUp: true
        }
      }
    case AUTH_SIGNUP_FAIL:
      {
        return {
          ...state,
          signUpError: action.signUpError
        }
      }
    case AUTH_RESET_PENDING:
      {
        return {
          ...state,
          loading: true,
          resetMessage: null,
          resetError: null
        }
      }
    case AUTH_RESET_SUCCESS:
      {
        return {
          ...state,
          loading: false,
          resetMessage: action.message
        }
      }
    case AUTH_RESET_FAIL:
      {
        return {
          ...state,
          loading: false,
          resetError: action.message
        }
      }
    case AUTH_LOGOUT:
      {
        return {
          ...state,
          accessToken: null,
          expiresAt: null,
          appMetadata: {},
          email: '',
          nickname: '',
          picture: '',
          authID: '',
          isLoggedIn: false,
          roles: [],
          org: ''
        }
      }

    case RESET_AUTH_ERRORS:
      {
        return {
          ...state,
          error: null,
          signUpError: null,
          resetMessage: null,
          resetError: null
        }
      }
    default:
      return state;
  }
};

const defaultAppMetadata = {
  counter: 0,
  maxCounter: 3,
  plan: {
    ads: true,
    download: false,
    endDate: '---',
    name: 'Basic',
    paymentMethod: '---',
    queue: false
  },
  stripeCustomerID: null,
  downloadCounter: 0,
  subscription: {
    status: 'INACTIVE',
    subscriptionID: '',
    type: ''
  },
  paymentHistory: []
}