import Config from 'src/common/Config'
// import axios from 'src/utils/axios';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import {
  GET_SINGLE_SUMMARY_PENDING,
  GET_SINGLE_SUMMARY_SUCCESS,

  GET_SINGLE_SUMMARIES_FAST_PENDING,
  GET_SINGLE_SUMMARIES_FAST_SUCCESS,
  GET_SINGLE_SUMMARIES_PENDING,
  GET_SINGLE_SUMMARIES_SUCCESS,

  POST_SINGLE_SUMMARY_PENDING,
  POST_SINGLE_SUMMARY_SUCCESS,
  POST_SINGLE_SUMMARY_FAIL,

  EDIT_SINGLE_SUMMARY_PENDING,
  EDIT_SINGLE_SUMMARY_SUCCESS,
  EDIT_SINGLE_SUMMARY_FAIL,

  DELETE_SINGLE_SUMMARY_PENDING,
  DELETE_SINGLE_SUMMARY_SUCCESS,

  ADD_SINGLE_SUMMARIES_PAGE,
  RESET_SINGLE_SUMMARIES,
} from '../types'



export const dispachBothSingleSummaries = (accessToken, page = 0, amount = 8, endFun = () => { }) => {
  return (dispatch) => {
    dispatch(getAllSingleSummariesFast(accessToken, page, amount, endFun));
    dispatch(getAllSingleSummaries(accessToken, page, amount, endFun));
  }
}

export const getAllSingleSummariesFast = (accessToken, page = 0, amount = 8, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_SUMMARIES_FAST_PENDING })
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/get-summaries`,
      params: { page, amount, type: 'fast', single: 'single' },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SINGLE_SUMMARIES_FAST_SUCCESS, list: res.data.list, more: res.data.more })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
    })
  }
}

export const getAllSingleSummaries = (accessToken, page = 0, amount = 8, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_SUMMARIES_PENDING })
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/get-summaries`,
      params: { page, amount, single: 'single' },
      // params: { page: 0, amount: 0 },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SINGLE_SUMMARIES_SUCCESS, list: res.data.list, more: res.data.more })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
    })
  }
}

export const addSinglePage = () => {
  return (dispatch) => dispatch({ type: ADD_SINGLE_SUMMARIES_PAGE })
}

export const getSingleSummary = (accessToken, name, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/get-summary`,
      data: {
        name: name,
        single: true
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SINGLE_SUMMARY_SUCCESS, summary: res.data })
      endFun('success')
      return res.data
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}


export const editSingleSummary = (accessToken, oldName, newName, newContent, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: EDIT_SINGLE_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/edit-summary`,
      data: {
        oldName,
        newName,
        content: newContent,
        single: true
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: EDIT_SINGLE_SUMMARY_SUCCESS, oldName, newName, content: newContent })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}

export const deleteSingleSummary = (accessToken, name, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SINGLE_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/delete-summary`,
      data: {
        name: name,
        single: true
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: DELETE_SINGLE_SUMMARY_SUCCESS, name: name })
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}


export const createNewSingleSummary = (accessToken, name, filesList, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: POST_SINGLE_SUMMARY_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/new-single-summary`,
      data: {
        name,
        filesList,
        single: true
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: RESET_SINGLE_SUMMARIES })
      dispatch({ type: POST_SINGLE_SUMMARY_SUCCESS, summary: res.data })
      endFun('success')
      return res.data
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}