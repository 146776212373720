export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT'
export const AUTH_LOGIN_PENDING = 'AUTH_LOGIN_PENDING';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_SIGNUP_PENDING = 'AUTH_SIGNUP_PENDING';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';
export const AUTH_RESET_PENDING = 'AUTH_RESET_PENDING';
export const AUTH_RESET_SUCCESS = 'AUTH_RESET_SUCCESS';
export const AUTH_RESET_FAIL = 'AUTH_RESET_FAIL';
export const AUTH_UPDATE = 'AUTH_UPDATE';
export const AUTH_UPDATE_PENDING = 'AUTH_UPDATE_PENDING';
export const SET_APP_METADATA = 'SET_APP_METADATA';

export const RESET_AUTH_ERRORS = 'RESET_AUTH_ERRORS'

export const GET_HISTORY_PENDING = 'GET_HISTORY_PENDING'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const CLEAR_HISTORY_PENDING = 'CLEAR_HISTORY_PENDING'
export const CLEAR_HISTORY_SUCCESS = 'CLEAR_HISTORY_SUCCESS'
export const GET_CHART_DAYS = 'GET_CHART_DAYS'
export const GET_CHART_MONTHS = 'GET_CHART_MONTHS'
export const ADD_TO_HISTORY = 'ADD_TO_HISTORY'

export const GET_SUMMARY_PENDING = 'GET_SUMMARY_PENDING'
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS'
export const GET_SUMMARIES_FAST_PENDING = 'GET_SUMMARIES_FAST_PENDING'
export const GET_SUMMARIES_FAST_SUCCESS = 'GET_SUMMARIES_FAST_SUCCESS'
export const GET_SUMMARIES_PENDING = 'GET_SUMMARIES_PENDING'
export const GET_SUMMARIES_SUCCESS = 'GET_SUMMARIES_SUCCESS'
export const POST_SUMMARY_PENDING = 'POST_SUMMARY_PENDING'
export const POST_SUMMARY_SUCCESS = 'POST_SUMMARY_SUCCESS'
export const POST_SUMMARY_FAIL = 'POST_SUMMARY_FAIL'
export const EDIT_SUMMARY_PENDING = 'EDIT_SUMMARY_PENDING'
export const EDIT_SUMMARY_SUCCESS = 'EDIT_SUMMARY_SUCCESS'
export const EDIT_SUMMARY_FAIL = 'EDIT_SUMMARY_FAIL'
export const DELETE_SUMMARY_PENDING = 'DELETE_SUMMARY_PENDING'
export const DELETE_SUMMARY_SUCCESS = 'DELETE_SUMMARY_SUCCESS'
export const ADD_SUMMARIES_PAGE = 'ADD_SUMMARIES_PAGE'
export const RESET_SUMMARIES = 'RESET_SUMMARIES'

export const GET_SINGLE_SUMMARY_PENDING = 'GET_SINGLE_SUMMARY_PENDING'
export const GET_SINGLE_SUMMARY_SUCCESS = 'GET_SINGLE_SUMMARY_SUCCESS'
export const GET_SINGLE_SUMMARIES_FAST_PENDING = 'GET_SINGLE_SUMMARIES_FAST_PENDING'
export const GET_SINGLE_SUMMARIES_FAST_SUCCESS = 'GET_SINGLE_SUMMARIES_FAST_SUCCESS'
export const GET_SINGLE_SUMMARIES_PENDING = 'GET_SINGLE_SUMMARIES_PENDING'
export const GET_SINGLE_SUMMARIES_SUCCESS = 'GET_SINGLE_SUMMARIES_SUCCESS'
export const POST_SINGLE_SUMMARY_PENDING = 'POST_SINGLE_SUMMARY_PENDING'
export const POST_SINGLE_SUMMARY_SUCCESS = 'POST_SINGLE_SUMMARY_SUCCESS'
export const POST_SINGLE_SUMMARY_FAIL = 'POST_SINGLE_SUMMARY_FAIL'
export const EDIT_SINGLE_SUMMARY_PENDING = 'EDIT_SINGLE_SUMMARY_PENDING'
export const EDIT_SINGLE_SUMMARY_SUCCESS = 'EDIT_SINGLE_SUMMARY_SUCCESS'
export const EDIT_SINGLE_SUMMARY_FAIL = 'EDIT_SINGLE_SUMMARY_FAIL'
export const DELETE_SINGLE_SUMMARY_PENDING = 'DELETE_SINGLE_SUMMARY_PENDING'
export const DELETE_SINGLE_SUMMARY_SUCCESS = 'DELETE_SINGLE_SUMMARY_SUCCESS'
export const ADD_SINGLE_SUMMARIES_PAGE = 'ADD_SINGLE_SUMMARIES_PAGE'
export const RESET_SINGLE_SUMMARIES = 'RESET_SINGLE_SUMMARIES'

export const GET_ALL_WORDS_PENDING = 'GET_ALL_WORDS_PENDING'
export const GET_ALL_WORDS_SUCCESS = 'GET_ALL_WORDS_SUCCESS'
export const GET_TRENDS_PENDING = 'GET_TRENDS_PENDING'
export const GET_TRENDS_SUCCESS = 'GET_TRENDS_SUCCESS'

export const GET_BLOGS_PENDING = 'GET_BLOGS_PENDING'
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS'

export const GET_AUTOMATIONS_PENDING = 'GET_AUTOMATIONS_PENDING'
export const GET_AUTOMATIONS_SUCCESS = 'GET_AUTOMATIONS_SUCCESS'

export const GET_ORG_PENDING = 'GET_ORG_PENDING'
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS'
export const ADD_USER_PENDING = 'ADD_USER_PENDING'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'
export const REMOVE_USER_PENDING = 'REMOVE_USER_PENDING'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAIL = 'REMOVE_USER_FAIL'
export const ADD_ONE_USED = 'ADD_ONE_USED'
export const RESET_ORG = 'RESET_ORG'

export const GET_ALL_FILES_PENDING = 'GET_ALL_FILES_PENDING'
export const GET_ALL_FILES_SUCCESS = 'GET_ALL_FILES_SUCCESS'
export const GET_FILE_LINK_PENDING = 'GET_FILE_LINK_PENDING'
export const GET_FILE_LINK_SUCCESS = 'GET_FILE_LINK_SUCCESS'
export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL'
export const DELETE_FILE_PENDING = 'DELETE_FILE_PENDING'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_ONE_FILE_SUCCESS = 'DELETE_ONE_FILE_SUCCESS'
export const RENAME_FILE_PENDING = 'RENAME_FILE_PENDING'
export const RENAME_FILE_SUCCESS = 'RENAME_FILE_SUCCESS'
export const GET_STORAGE_SIZE_SUCCESS = 'GET_STORAGE_SIZE_SUCCESS'
export const RESET_FILES = 'RESET_FILES'

export const GET_SLACK_INFO_PENDING = 'GET_SLACK_INFO_PENDING'
export const GET_SLACK_INFO_SUCCESS = 'GET_SLACK_INFO_SUCCESS'
export const GET_SLACK_CHANNELS_PENDING = 'GET_SLACK_CHANNELS_PENDING'
export const GET_SLACK_CHANNELS_SUCCESS = 'GET_SLACK_CHANNELS_SUCCESS'

export const GET_ONEDRIVE_INFO_PENDING = 'GET_ONEDRIVE_INFO_PENDING'
export const GET_ONEDRIVE_INFO_SUCCESS = 'GET_ONEDRIVE_INFO_SUCCESS'
export const GET_ONEDRIVE_FILES_PENDING = 'GET_ONEDRIVE_FILES_PENDING'
export const GET_ONEDRIVE_FILES_SUCCESS = 'GET_ONEDRIVE_FILES_SUCCESS'

export const GET_GOOGLE_DRIVE_INFO_PENDING = 'GET_GOOGLE_DRIVE_INFO_PENDING'
export const GET_GOOGLE_DRIVE_INFO_SUCCESS = 'GET_GOOGLE_DRIVE_INFO_SUCCESS'
export const GET_GOOGLE_DRIVE_FILES_PENDING = 'GET_GOOGLE_DRIVE_FILES_PENDING'
export const GET_GOOGLE_DRIVE_FILES_SUCCESS = 'GET_GOOGLE_DRIVE_FILES_SUCCESS'