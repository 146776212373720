import {
  GET_SLACK_INFO_PENDING,
  GET_SLACK_INFO_SUCCESS,

  GET_SLACK_CHANNELS_PENDING,
  GET_SLACK_CHANNELS_SUCCESS
} from '../types'

const initialState = {
  loading: false,

  active: false,
  defaultChannel: {
    name: '---',
    id: '---'
  },
  workspaceName: '---',
  lastUpdated: '---',

  channelsLoading: false,
  channelsList: []
};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_SLACK_INFO_PENDING:
      {
        return {
          ...state,
          loading: true
        }
      }

    case GET_SLACK_INFO_SUCCESS:
      {
        return {
          ...state,
          loading: false,
          active: action.active,
          defaultChannel: action.defaultChannel,
          workspaceName: action.workspaceName,
          lastUpdated: action.lastUpdated,

          channelsLoading: false
        }
      }

    case GET_SLACK_CHANNELS_PENDING:
      {
        return {
          ...state,
          channelsLoading: true
        }
      }

    case GET_SLACK_CHANNELS_SUCCESS:
      {
        return {
          ...state,
          channelsLoading: false,
          channelsList: action.list,

          loading: false
        }
      }

    default:
      return state;
  }
}