import Config from 'src/common/Config'
// import axios from 'src/utils/axios';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import {
  GET_ORG_PENDING,
  GET_ORG_SUCCESS,

  ADD_USER_PENDING,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,

  REMOVE_USER_PENDING,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,
} from '../types'

export const getOrgInfo = (accessToken, endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: GET_ORG_PENDING })
    axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/auth/org-info`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      let maxGb = res.data.maxGb
      res.data.packs.forEach(pack => {
        if (pack.maxGb && pack.maxGb > maxGb) maxGb = pack.maxGb
      })

      dispatch({ type: GET_ORG_SUCCESS, info: { ...res.data, maxGb: maxGb } })
      endFun('success')
    }).catch(err => {
      endFun('fail')
    })
  }
}

export const addUser = (accessToken, email, roles = ['viewer'], endFun = () => { }) => {
  return (dispatch) => {
    dispatch({ type: ADD_USER_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/auth/add-user`,
      data: {
        email,
        roles: roles
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: ADD_USER_SUCCESS })
      dispatch(getOrgInfo(accessToken))
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      dispatch({ type: ADD_USER_FAIL, error: err.response.error })
      return err.response.error
    })
  }
}


export const startRemovingUser = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_USER_PENDING })
  }
}

export const endRemovingUser = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_USER_SUCCESS })
  }
}

export const removeUser = (accessToken, email, endFun = () => { }) => {
  return (dispatch) => {
    // dispatch({ type: REMOVE_USER_PENDING })
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/auth/remove-user`,
      data: {
        email
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      // dispatch({ type: REMOVE_USER_SUCCESS })
      // dispatch(getOrgInfo(accessToken))
      endFun('success')
      return 'success'
    }).catch(err => {
      endFun('fail')
      dispatch({ type: REMOVE_USER_FAIL, error: err.response.error })
      return 'fail'
    })
  }
}

export const cancelSubscription = (accessToken, subType, endFun = () => { }) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/${subType}/cancel-subscription`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      endFun('success')
      dispatch(getOrgInfo(accessToken))
      return res.data
    }).catch(err => {
      endFun('fail')
      return 'fail'
    })
  }
}

export const shareEmail = (accessToken, title, content, date, emailList) => {
  return axios({
    method: 'post',
    url: `${Config.apiBaseUrl}/share-email`,
    headers: {
      'authorization': `Bearer ${accessToken}`
    },
    data: {
      title, 
      content, 
      date, 
      emailList
    }
  }).then(res => {
    return 'success'
  }).catch(err => {
    return 'fail'
  })
}

export const changeDefaultEmailShareList = (accessToken, emailList) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/set-default-share-emails`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      },
      data: {
        emailList
      }
    }).then(res => {
      dispatch(getOrgInfo(accessToken))
      return 'success'
    }).catch(err => {
      return 'fail'
    })
  }
}