import Config from 'src/common/Config'
import axios from 'src/utils/axios';

import {
  GET_GOOGLE_DRIVE_INFO_PENDING,
  GET_GOOGLE_DRIVE_INFO_SUCCESS,

  GET_GOOGLE_DRIVE_FILES_PENDING,
  GET_GOOGLE_DRIVE_FILES_SUCCESS
} from '../types'

const SOURCE = 'googledrive'

const mapFiles = (data, previousPath = '') => {
  let dirName = data.name ? `${data.name}` : ''
  let currentPath = previousPath !== '' ? `${previousPath}/${dirName}` : dirName
  let filesList = data.items
  filesList = filesList.filter(f => f !== null)

  let library = {}
  let allFiles = []

  let currentFiles = filesList.map(f => {
    if (typeof f === 'string') {
      let fSplit = f.split(Config.fileSourceSeperator)
      let fileId = fSplit[0]
      let fileName = fSplit.slice(1).join(Config.fileSourceSeperator)

      return { source: SOURCE, type: 'file', id: fileId, name: fileName, fullPath: data.name ? `${currentPath}/${fileName}` : fileName }
    }

    let [tmpLib, tmpFiles] = mapFiles(f, currentPath)
    library = { ...library, ...tmpLib }
    allFiles = allFiles.concat(tmpFiles)

    return { source: SOURCE, type: 'folder', id: f.id, parentId: f.parentId, name: f.name, fullPath: data.name ? `${currentPath}/${f.name}` : f.name }
  })
  currentFiles = currentFiles.filter(f => f.name !== '')

  library[currentPath] = currentFiles
  allFiles = allFiles.concat(currentFiles)
  allFiles = allFiles.filter(f => f.type !== 'folder')

  return [library, allFiles]
}

export const checkGoogleDriveIntegration = (accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/googledrive/check`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch(getGoogleDriveInfo(accessToken))
      return res.data.active
    }).catch(err => {
      console.log(err)
      return 'fail'
    })
  }
}

export const getGoogleDriveInfo = (accessToken) => {
  return (dispatch) => {
    dispatch({ type: GET_GOOGLE_DRIVE_INFO_PENDING })

    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/googledrive/info`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_GOOGLE_DRIVE_INFO_SUCCESS, ...res.data })
      return 'success'
    }).catch(err => {
      console.log(err)
      return 'fail'
    })
  }
}

export const getGoogleDriveFiles = (accessToken, path, id) => {
  return (dispatch) => {
    dispatch({ type: GET_GOOGLE_DRIVE_FILES_PENDING })
    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/googledrive/files`,
      params: {
        path,
        id
      },
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {
      let [library, allFiles] = mapFiles(res.data)

      dispatch({ type: GET_GOOGLE_DRIVE_FILES_SUCCESS, list: allFiles, library: library })
      return allFiles
    }).catch(err => {
      dispatch({ type: GET_GOOGLE_DRIVE_FILES_SUCCESS, list: [], library: {} })
      return 'fail'
    })
  }
}

export const addGoogleDrive = (accessToken, code) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/googledrive/add-account`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      },
      data: {
        code: code
      }
    }).then(res => {

      dispatch({ type: GET_GOOGLE_DRIVE_INFO_SUCCESS, ...res.data })
      return res.data
    }).catch(err => {
      console.log(err)
      return err
    })
  }
}

export const uploadGoogleDrive = (accessToken, title, content) => {
  return axios({
    method: 'post',
    url: `${Config.apiBaseUrl}/googledrive/upload`,
    headers: {
      'authorization': `Bearer ${accessToken}`
    },
    data: {
      title,
      content,
    }
  }).then(res => {

    return 'success'
  }).catch(err => {
    return err
  })
}

export const downloadGoogleDriveFile = (accessToken, fullPath, fileId) => {
  return axios({
    method: 'post',
    url: `${Config.apiBaseUrl}/googledrive/download`,
    headers: {
      'authorization': `Bearer ${accessToken}`
    },
    data: {
      fullPath, 
      fileId
    }
  }).then(res => {

    return res.data
  }).catch(err => {
    return null
  })
}