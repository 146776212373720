import Config from 'src/common/Config'
import axios from 'src/utils/axios';

import {
  GET_SLACK_INFO_PENDING,
  GET_SLACK_INFO_SUCCESS,

  GET_SLACK_CHANNELS_PENDING,
  GET_SLACK_CHANNELS_SUCCESS
} from '../types'

export const getSlackInfo = (accessToken) => {
  return (dispatch) => {
    dispatch({ type: GET_SLACK_INFO_PENDING })

    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/slack/info`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SLACK_INFO_SUCCESS, ...res.data })
      return 'success'
    }).catch(err => {
      console.log(err)
      return 'fail'
    })
  }
}

export const getSlackChannels = (accessToken) => {
  return (dispatch) => {
    dispatch({ type: GET_SLACK_CHANNELS_PENDING })

    return axios({
      method: 'get',
      url: `${Config.apiBaseUrl}/slack/channels`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch({ type: GET_SLACK_CHANNELS_SUCCESS, list: res.data.list })
      return res.data.list
    }).catch(err => {
      console.log(err)
      return 'fail'
    })
  }
}

export const changeDefaultSlackChannel = (accessToken, channelName, channelId) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/slack/set-default-channel`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      },
      data: {
        channelName,
        channelId
      }
    }).then(res => {

      dispatch(getSlackInfo(accessToken))
      return res.data
    }).catch(err => {
      console.log(err)
      return 'fail'
    })
  }
}

export const addSlack = (accessToken, code) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/slack/add-workspace`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      },
      data: {
        code: code
      }
    }).then(res => {

      dispatch({ type: GET_SLACK_INFO_SUCCESS, ...res.data })
      return res.data
    }).catch(err => {
      console.log(err)
      return err
    })
  }
}

export const removeSlack = (accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${Config.apiBaseUrl}/slack/remove-workspace`,
      headers: {
        'authorization': `Bearer ${accessToken}`
      }
    }).then(res => {

      dispatch(getSlackInfo(accessToken))
      return res.data
    }).catch(err => {
      return 'fail'
    })
  }
}

export const shareSlack = (accessToken, channelId, title, content, date) => {
  return axios({
    method: 'post',
    url: `${Config.apiBaseUrl}/slack/share`,
    headers: {
      'authorization': `Bearer ${accessToken}`
    },
    data: {
      channelId, 
      title, 
      content, 
      date
    }
  }).then(res => {

    return 'success'
  }).catch(err => {
    return err
  })
}