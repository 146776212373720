import {
  GET_ORG_PENDING,
  GET_ORG_SUCCESS,

  ADD_USER_PENDING,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,

  REMOVE_USER_PENDING,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,

  ADD_ONE_USED,
  RESET_ORG
} from '../types'

const initialState = {
  loading: false,
  error: null,

  addUserLoading: false,
  addUserError: null,

  removeUserLoading: false,
  removeUserError: null,

  defaultEmailShares: [],

  name: "",
  'users-list': [],
  users: {},
  plan: {
    name: "---",
    startDate: '---',
    activeTill: '---',
    paymentMethod: '---',
  },
  packs: [],
  subscription: {
    method: "",
    subscriptionID: "",
    status: 'INACTIVE'
  },
  maxSummaries: 1,
  maxGb: 1,
  used: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case RESET_ORG:
      {
        return {
          ...state,
          loading: true,
          error: null,
          name: "",
          'users-list': [],
          users: {},
          plan: {
            name: "---",
            startDate: '---',
            activeTill: '---',
            paymentMethod: '---',
          },
          packs: [],
          subscription: {
            method: "",
            subscriptionID: "",
            status: 'INACTIVE'
          },
          maxSummaries: 1,
          maxGb: 1,
          used: 0,
        }
      }


    case GET_ORG_PENDING:
      {
        return {
          ...state,
          loading: true,
          error: null,
        }
      }
    case GET_ORG_SUCCESS:
      {

        return {
          ...state,
          loading: false,
          error: null,

          ...action.info,
        }
      }


    case ADD_USER_PENDING:
      {
        return {
          ...state,
          addUserLoading: true,
          addUserError: null
        }
      }
    case ADD_USER_SUCCESS:
      {

        return {
          ...state,
          addUserLoading: false,
          addUserError: null
        }
      }
    case ADD_USER_FAIL:
      {

        return {
          ...state,
          addUserLoading: false,
          addUserError: action.error
        }
      }


    case REMOVE_USER_PENDING:
      {
        return {
          ...state,
          removeUserLoading: true,
          removeUserError: null
        }
      }
    case REMOVE_USER_SUCCESS:
      {

        return {
          ...state,
          removeUserLoading: false,
          removeUserError: null
        }
      }
    case REMOVE_USER_FAIL:
      {

        return {
          ...state,
          removeUserLoading: false,
          removeUserError: action.error
        }
      }

    case ADD_ONE_USED:
      {
        let newUsed = state.used
        let newPacks = state.packs

        if (state.maxSummaries > state.used) newUsed = state.used + 1
        else {
          for (let i = 0; i < newPacks.length; i++) {
            let pack = newPacks[i]
            let endDate = new Date(pack.endDate)

            if (endDate > Date.now() && pack.maxSummaries > pack.used) {
              pack.used = pack.used + 1
              newPacks[i] = pack

              break
            }
          }
        }

        return {
          ...state,
          used: newUsed,
          packs: newPacks
        }
      }

    default:
      return state;
  }
};