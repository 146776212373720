import {
  GET_GOOGLE_DRIVE_INFO_PENDING,
  GET_GOOGLE_DRIVE_INFO_SUCCESS,

  GET_GOOGLE_DRIVE_FILES_PENDING,
  GET_GOOGLE_DRIVE_FILES_SUCCESS
} from '../types'

const initialState = {
  loading: false,

  active: false,

  username: '---',
  lastUpdated: '---',

  libraryLoading: false,
  files: [],
  library: {},
};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_GOOGLE_DRIVE_INFO_PENDING:
      {
        return {
          ...state,
          loading: true
        }
      }

    case GET_GOOGLE_DRIVE_INFO_SUCCESS:
      {
        return {
          ...state,
          loading: false,
          active: action.active,
          username: action.username,
          lastUpdated: action.lastUpdated,
        }
      }

    case GET_GOOGLE_DRIVE_FILES_PENDING:
      {
        return {
          ...state,
          libraryLoading: true
        }
      }

    case GET_GOOGLE_DRIVE_FILES_SUCCESS:
      {

        let newLibrary = {
          ...state.library,
          ...action.library
        }

        let alreadyIn = []

        let newFilesList = state.files.map(f => {
          alreadyIn.push(f.fullPath)

          return f
        })
        
        action.list.forEach(f => {
          if (alreadyIn.includes(f.fullPath)) return 

          newFilesList.push(f)
        })

        return {
          ...state,
          libraryLoading: false,

          files: newFilesList,
          library: newLibrary,

          loading: false
        }
      }

    default:
      return state;
  }
}