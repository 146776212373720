import clsx from 'clsx';
import { Icon } from '@iconify/react';
import Logo from 'src/components/LogoFull';
import React, { useState, useRef } from 'react';
import useOffSetTop from 'src/hooks/useOffSetTop';
import homeFill from '@iconify-icons/eva/home-fill';
import PopoverMenu from 'src/components/PopoverMenu';
import roundSpeed from '@iconify-icons/ic/round-speed';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
import { PATH_HOME, PATH_PAGE, PATH_DOCS, PATH_APP } from 'src/routes/paths';
import bookOpenFill from '@iconify-icons/eva/book-open-fill';
import roundStreetview from '@iconify-icons/ic/round-streetview';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Link,
  Button,
  AppBar,
  Hidden,
  Toolbar,
  MenuItem,
  Container,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { MIconButton } from 'src/theme';

import useAuth from 'src/hooks/useAuth'

// ----------------------------------------------------------------------

const MENU_LINKS = [
  { title: 'Home', icon: homeFill, href: '/' },
  // { title: 'Documentation', icon: bookOpenFill, href: PATH_DOCS.root },
  { title: 'Use Cases', icon: bookOpenFill, href: PATH_HOME.useCases },
  { title: 'Blog', icon: bookOpenFill, href: PATH_PAGE.blog },
  { title: 'Pricing', icon: bookOpenFill, href: PATH_PAGE.pricing },
  { title: 'Login', icon: bookOpenFill, href: PATH_PAGE.auth.login },
];

const MENU_LINKS_LOGED_IN = [
  { title: 'Home', icon: homeFill, href: '/' },
  // { title: 'Documentation', icon: bookOpenFill, href: PATH_DOCS.root },
  { title: 'Use Cases', icon: bookOpenFill, href: PATH_HOME.useCases },
  { title: 'Blog', icon: bookOpenFill, href: PATH_PAGE.blog },
  { title: 'Pricing', icon: bookOpenFill, href: PATH_PAGE.pricing },
  // { title: 'Dashboard', icon: roundSpeed, href: PATH_HOME.dashboard },
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
      height: APP_BAR_DESKTOP
    }
  },
  // isHome: {
  //   color: theme.palette.common.white
  // },
  isDesktopActive: {
    color: theme.palette.primary.main
  },
  isMobileActive: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    )
  },
  onScroll: {
    '& $toolbar': {
      backgroundColor: theme.palette.background.default
    },
    '& $isHome': {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.up('md')]: {
      '& $toolbar': {
        height: APP_BAR_DESKTOP - 20
      }
    }
  }
}));

// ----------------------------------------------------------------------

function TopBar() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(50);
  const [openMenu, setOpenMenu] = useState(false);
  const isHome = pathname === '/';

  const { isAuthenticated } = useAuth()

  let menuLinks = MENU_LINKS
  if (isAuthenticated) menuLinks = MENU_LINKS_LOGED_IN

  const renderMenuDesktop = (
    <div>
      {menuLinks.map((link) => (
        <Link
          exact
          to={link.href}
          key={link.title}
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          activeClassName={classes.isDesktopActive}
          className={clsx({
            [classes.isHome]: isHome
          })}
          sx={{ mr: 5, color: 'text.primary' }}
        >
          {link.title}
        </Link>
      ))}
    </div>
  );

  const renderMenuMobile = (
    <PopoverMenu
      width={220}
      open={openMenu}
      anchorEl={anchorRef.current}
      onClose={() => setOpenMenu(false)}
    >
      <List>
        {menuLinks.map((link) => (
          <MenuItem
            exact
            to={link.href}
            key={link.title}
            component={RouterLink}
            onClick={() => setOpenMenu(false)}
            activeClassName={classes.isMobileActive}
            sx={{ color: 'text.secondary' }}
          >
            {/* <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon> */}
            <ListItemText>{link.title}</ListItemText>
          </MenuItem>
        ))}
      </List>
    </PopoverMenu>
  );

  return (
    <AppBar
      color="transparent"
      className={clsx(classes.root, { [classes.onScroll]: offset })}
      sx={{ boxShadow: 'none' }}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Container
          maxWidth="lg"
          sx={{
            lineHeight: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <Hidden mdDown>{renderMenuDesktop}</Hidden>

          <Button
            underline="none"
            variant="contained"
            component={RouterLink}
            exact
            to={isAuthenticated ? PATH_HOME.dashboard : PATH_PAGE.auth.register}
          >
            {isAuthenticated ? "Dashboard" : "Start free trial"}
          </Button>

          <Hidden mdUp>
            <MIconButton
              ref={anchorRef}
              onClick={() => setOpenMenu(true)}
              className={clsx({
                [classes.isHome]: isHome
              })}
            >
              <Icon icon={menu2Fill} />
            </MIconButton>
            {renderMenuMobile}
          </Hidden>
        </Container>
      </Toolbar>
      {offset && (
        <Box
          sx={{
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            margin: 'auto',
            borderRadius: '50%',
            position: 'absolute',
            width: `calc(100% - 48px)`,
            // boxShadow: (theme) => theme.shadows[25].z8
          }}
        />
      )}
    </AppBar>
  );
}

export default TopBar;
