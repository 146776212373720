import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';

import auth from './reducers/auth'
import summaries from './reducers/summaries'
import singleSummaries from './reducers/singleSummaries'
import org from './reducers/org'
import storageReducer from './reducers/storage'
import blog from './reducers/blog'
import slack from './reducers/slack'
import oneDrive from './reducers/oneDrive'
import googleDrive from './reducers/googleDrive'
import automations from './reducers/automations'

// ----------------------------------------------------------------------

const generateGeneralConfig = (name) => ({
  key: name,
  storage: storage,
  keyPrefix: 'redux-',
})

const generateLibraryConfig = (name) => ({
  key: name,
  storage: storage,
  keyPrefix: 'redux-',
  blacklist: ['library', 'files']
})

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const summariesPersistConfig = {
  key: 'summary',
  storage: storage,
  keyPrefix: 'redux-',
  blacklist: ['summary', 'page', 'summaries', 'fastSummaries']
};

const singleSummariesPersistConfig = {
  key: 'single-summary',
  storage: storage,
  keyPrefix: 'redux-',
  blacklist: ['summary', 'page', 'summaries', 'fastSummaries']
};

const rootReducer = combineReducers({
  settings: settingsReducer,

  auth: persistReducer(generateGeneralConfig('auth'), auth),
  summaries: persistReducer(summariesPersistConfig, summaries),
  singleSummaries: persistReducer(singleSummariesPersistConfig, singleSummaries),
  org: persistReducer(generateGeneralConfig('org'), org),
  storage: persistReducer(generateGeneralConfig('storage'), storageReducer),
  blog: persistReducer(generateGeneralConfig('blog'), blog),
  slack: persistReducer(generateGeneralConfig('slack'), slack),
  oneDrive: persistReducer(generateLibraryConfig('oneDrive'), oneDrive),
  googleDrive: persistReducer(generateLibraryConfig('googleDrive'), googleDrive),
  automations: persistReducer(generateLibraryConfig('automations'), automations),
});

export { rootPersistConfig, rootReducer };
