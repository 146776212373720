import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Base64 } from 'js-base64';

import { initAuth, isValidToken, refreshTokenFun, logout } from 'src/redux/actions'

// ----------------------------------------------------------------------

const sections = ["TXVsdGktRG9tYWluIEF1dG9tYXRpYy", "BTZWFyY2ggU3VtbWFyaXphdGlvbi4="]

function decodeToken(token) {
  var newToken = Base64.decode(token)
  var positions = newToken.slice(newToken.length - 12).match(/.{1,2}/g).reverse()
  newToken = newToken.slice(0, newToken.length - 12)
  sections.reverse().map((section, index) => {
    var sectionPostions = positions.slice(0 + (index * 3), 3 + (index * 3))
    var divide = parseInt(sectionPostions[2])

    var tmp = [section.slice(divide), section.slice(0, divide)]
    tmp.map((str, index2) => {
      var place = sectionPostions[index2]
      newToken = newToken.slice(0, place) + newToken.slice(place).replace(str, '')
    })
  })

  var result = Base64.decode(newToken)
  return result
}

// ----------------------------------------------------------------------

function intervalCheckToken(endFunction = () => { }) {
  return (dispatch) => {
    var interval = setInterval(async () => {
      const accessToken = await localStorage.getItem('@Brevi:accessToken')
      let { valid, diff } = isValidToken(accessToken)
      if (!valid) {
        clearInterval(interval)
        return
      }

      diff = diff / 60 //minutes
      diff = diff / 60 //hours
      if (diff < 1) {
        console.log('making inverval refresh login', diff)
        var refreshToken = await localStorage.getItem('@Brevi:refreshToken')
        var checker = await localStorage.getItem('@Brevi:refreshTokenChecker')
        let decodedToken = false
        while (!decodedToken) {
          try {
            let tmpRefreshToken = decodeToken(refreshToken)
            if (tmpRefreshToken.startsWith(checker.slice(0, 3)) && tmpRefreshToken.endsWith(checker.slice(3))) {
              refreshToken = tmpRefreshToken
              decodedToken = true
            }
          } catch (err) { }
        }
        if (refreshToken !== null || refreshToken !== '' || refreshToken !== undefined || refreshToken !== 'undefined') dispatch(refreshTokenFun(refreshToken, () => endFunction()))
        else dispatch(logout(() => endFunction()))
      }
    }, 30000)

    return interval
  }
}

// ----------------------------------------------------------------------

Auth0Provider.propTypes = {
  children: PropTypes.node
};

function Auth0Provider({ children }) {
  const dispatch = useDispatch();
  const [currentInterval, setCurrentInterval] = React.useState(null)

  const doneAuth = () => {
    setTimeout(() => {
      let interval = dispatch(intervalCheckToken(() => doneAuth()))
      if (currentInterval) clearInterval(currentInterval)

      setCurrentInterval(interval)
    }, 2000)
  }

  useEffect(() => {
    dispatch(initAuth(() => doneAuth()))
  }, [dispatch]);

  return <>{children}</>;
}

export default Auth0Provider;
